import { Injectable } from '@angular/core';
import { TableColumnsProp } from '../models/tables.model';
import { hyperlinkLabels, pageType } from '../utility/constants';
@Injectable({
  providedIn: 'root',
})
export class CommonTableService {
  /* Display the selected columns in the table */
  public displaySelectedColumnsToTable(
    columnName: string,
    customiseTableColumns: TableColumnsProp[],
    displayedColumns: string[]
  ) {
    const isColumnView = customiseTableColumns.filter(
      data => data.key === columnName && data.isSelected
    );
    if (isColumnView && isColumnView.length > 0) return true;
    else {
      const columnIndex = displayedColumns.indexOf(columnName);
      if (columnIndex > -1) {
        displayedColumns.splice(columnIndex, 1);
      }
      return false;
    }
  }

  public isHyperlink(label: string, isFrom: string) {
    if (isFrom === pageType.mawb) {
      return label === hyperlinkLabels.mawb;
    } else if (isFrom === pageType.agent) {
      return label === hyperlinkLabels.agent;
    } else if (isFrom === pageType.hawb) {
      return label === hyperlinkLabels.hawb;
    } else if (isFrom === pageType.manifest) {
      return label === hyperlinkLabels.manifest;
    } else if (isFrom === pageType.bag) {
      return label === hyperlinkLabels.bag;
    } else if (isFrom === pageType.account) {
      return label === hyperlinkLabels.account;
    } else if (isFrom === pageType.smRolesAndPermissions) {
      return label === hyperlinkLabels.smRolesAndPermissions;
    } else if (isFrom === pageType.userManagement) {
      return label === hyperlinkLabels.fullName;
    }
    return false;
  }
}
