import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  IFieldsAndTabsData,
  IRolesAndPermissionSelectionPageData,
  RolesAndPermissionsColumnData,
} from 'src/app/features/model/sm-rolesAndPermissions';
import {
  FetchRolesAndPermissionsList,
  FetchRolesInformationListSuccess,
  FetchSelectedRoleById,
  ResetRolesAndPermissionsList,
  ResetRolesInformationList,
  SetFieldsAndTabsData,
  SetPageSelectionData,
} from './roles-permissions.action';
import { updateState } from 'src/app/shared/utility/store.utility';

// state to store api data
@State<IFieldsAndTabsData>({
  name: 'rolesfieldsAndTabs',
  defaults: {
    moduleHierarchy: [],
    fieldsInfo: [],
  },
})
@Injectable()
export class FieldsAndTabsState {
  @Action(SetFieldsAndTabsData)
  setFieldsAndTabsData(
    ctx: StateContext<IFieldsAndTabsData>,
    action: SetFieldsAndTabsData
  ) {
    ctx.setState(action.payload);
  }
}

// state to store selection data for each page
@State<IRolesAndPermissionSelectionPageData>({
  name: 'rolesAndPermissionPageSelectionData',
  defaults: {
    pageSelectionData: [],
  },
})
@Injectable()
export class RolesAndPermissionPageSelectionState {
  @Action(SetPageSelectionData)
  setFieldsAndTabsData(
    ctx: StateContext<IRolesAndPermissionSelectionPageData>,
    action: SetPageSelectionData
  ) {
    ctx.setState(action.payload);
  }
}

export interface RoleInformationStateModel {
  roleInformation: any;
  rolesInformationList: any[];
}

@State<RoleInformationStateModel>({
  name: 'roleInformation',
  defaults: {
    roleInformation: {},
    rolesInformationList: [],
  },
})
@Injectable()
export class RoleInformationState {
  @Action(FetchRolesInformationListSuccess)
  fetchRolesInformationListSuccess(
    { getState, setState }: StateContext<RoleInformationStateModel>,
    { roles }: FetchRolesInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      rolesInformationList: roles,
    });
  }

  @Action(FetchSelectedRoleById)
  FetchSelectedRoleById(
    ctx: StateContext<RoleInformationStateModel>,
    action: FetchSelectedRoleById
  ) {
    const state = ctx.getState();
    const selectedRole = state.rolesInformationList.find(
      element => element.id === action.roleId
    );

    updateState(ctx, {
      roleInformation: selectedRole || {},
    });
  }

  @Action(ResetRolesInformationList)
  resetRoleInfo(ctx: StateContext<RoleInformationStateModel>) {
    updateState(ctx, {
      roleInformation: {},
      rolesInformationList: [],
    });
  }
}

export interface RolesAndPermissionsListStateModel {
  rolesAndPermissions: RolesAndPermissionsColumnData[];
  totalData: number;
}

@Injectable()
@State<RolesAndPermissionsListStateModel>({
  name: 'rolesAndPermissionsList',
  defaults: {
    rolesAndPermissions: [],
    totalData: 0,
  },
})
export class RolesAndPermissionsListState {
  @Action(FetchRolesAndPermissionsList)
  FetchHawbsList(
    ctx: StateContext<RolesAndPermissionsListStateModel>,
    action: FetchRolesAndPermissionsList
  ) {
    const payload = action.payload;
    if (payload.data?.rolesAndPermissions && payload.data?.totalRecords) {
      updateState(ctx, {
        rolesAndPermissions: payload.data.rolesAndPermissions,
        totalData: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        rolesAndPermissions: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetRolesAndPermissionsList)
    ResetUserList(ctx: StateContext<RolesAndPermissionsListStateModel>) {
      updateState(ctx, {
        rolesAndPermissions: [],
        totalData: 0,
      });
    }
}
