import {
  IFieldsAndTabsData,
  IRolesAndPermissionSelectionPageData,
  RolesAndPermissionsSearchPayload,
} from 'src/app/features/model/sm-rolesAndPermissions';

export class SetFieldsAndTabsData {
  static readonly type = '[FieldsAndTabs] Set Fields and Tabs Data';
  constructor(public payload: IFieldsAndTabsData) {}
}

export class SetPageSelectionData {
  static readonly type = '[Roles And Permisisons] Set Page Selection Data';
  constructor(public payload: IRolesAndPermissionSelectionPageData) {}
}

export class FetchRolesInformationListSuccess {
  static readonly type = '[Role] Fetch Roles Information List Success';
  constructor(public roles: any[]) {}
}

export class FetchSelectedRoleById {
  static readonly type = '[Roles] Fetch Selected Role By Id';
  constructor(public roleId: number) {}
}

export class ResetRolesInformationList {
  static readonly type = '[Roles] Reset Roles Information List';
}

export class FetchRolesAndPermissionsList {
  static readonly type = '[Roles Permissions] List';
  constructor(public payload: RolesAndPermissionsSearchPayload) {}
}

export class ResetRolesAndPermissionsList {
  static readonly type = '[Roles Permissions] Reset Roles and Permissions List';
}
