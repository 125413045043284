import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { TableService } from '../tables/tables.service';
import { TableColumnsProp } from '../../models/tables.model';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-menu-overlay',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './menu-overlay.component.html',
  styleUrl: './menu-overlay.component.scss',
})
export class MenuOverlayComponent implements OnInit {
  public summaryCustomiseTableColumns!: TableColumnsProp[];
  public displayedColumns!: string[];
  public reorderedColumns!: string[];
  public defaultSelectedColumn!: string;
  public isFrom!: string;
  constructor(private readonly tableService: TableService) {}
  ngOnInit(): void {
    this.fetchRequiredDataFromTableService();
  }

  public handleCustomizeCheckboxSelection(index: number, isSelected: boolean) {
    this.tableService.handleTableCustomizeCheckboxSelection(
      index,
      isSelected,
      this.summaryCustomiseTableColumns,
      this.displayedColumns,
      this.reorderedColumns,
      false
    );
  }

  private fetchRequiredDataFromTableService() {
    this.tableService.tableDisplayedColumns.subscribe(data => {
      this.displayedColumns = data;
    });
    this.tableService.customizeMenuColumns.subscribe(data => {
      this.summaryCustomiseTableColumns = data;
    });
    this.tableService.reorderedColumns.subscribe(data => {
      this.reorderedColumns = data;
    });
    this.tableService.defaultSelectedColumn.subscribe(data => {
      this.defaultSelectedColumn = data;
    });
  }
}
