export const GridEventColumns = [
  {
    key: 'date',
    label: 'Date, Time, Time Zone',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'eventType',
    label: 'Event Type',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'performedBy',
    label: 'Performed By',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'description',
    label: 'Description',
    isSelected: true,
    defaultPrintWidth: 200,
  },
];

export interface EventsColumnData {
  date: string;
  eventType: string;
  performedBy: string;
  description: string;
}
