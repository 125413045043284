import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLConstants } from '../utility/url-constants';
import { BaseService } from 'src/app/core/services/base.httpService';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(private readonly baseService: BaseService) {}

  public getDepartmentAndRoles(): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.getDepartmentAndRole,
      '',
      'json'
    );
  }
}
