import { Component, Input } from '@angular/core';
import {
  loginInfoColumnConfig,
} from '../../user-management.config';
import { selectionTableNames, StatusCodes } from 'src/app/shared/utility/constants';
import { UserService } from 'src/app/features/service/user.service';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@Component({
  selector: 'app-login-information',
  templateUrl: './login-information.component.html',
  styleUrl: './login-information.component.scss',
})
export class LoginInformationComponent {
  @Input() userId: any;
  public loginInfoTableData = [];
  public loginInfoColumnConfig = loginInfoColumnConfig;
  public tableName = selectionTableNames.UserLoginInfo;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly userService: UserService,
    private readonly errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit() {
    if (this.userId) {
      this.getUserHistoryByUserId(this.userId);
    }
  }

  private getUserHistoryByUserId(userId: any) {
    this.userService
      .fetchUserLoginHistory(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.statusCode === StatusCodes.Success) {
            this.loginInfoTableData = response.data;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }
}
