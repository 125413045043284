<div
  class="message-popup-wrapper {{
    messagePopupData.code?.isForWarning ? 'warning' : ''
  }}">
  <div
    class="message-popup-header"
    *ngIf="!messagePopupData.code?.isForWarning">
    <div class="ibc-body-text-large-bold">{{ messagePopupData.title }}</div>
    <div class="card-action-icons">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePopup('close')"></app-svg-icon>
    </div>
  </div>

  <div
    class="message-popup-content {{
      messagePopupData.code?.isForDownload
        ? 'pb-20'
        : !messagePopupData.code?.isMultiple &&
            messagePopupData.code?.isOptionalTextbox
          ? 'pb-0'
          : ''
    }}">
    <div
      class="ibc-body-text-medium confirmation-message"
      *ngIf="
        messagePopupData.code?.message && !messagePopupData.code?.isForWarning
      ">
      {{ messagePopupData.code?.message }}
    </div>
    <div
      class="ibc-body-text-medium confirmation-message"
      *ngIf="
        messagePopupData.code?.secondMessage &&
        !messagePopupData.code?.isForWarning
      ">
      {{ messagePopupData.code?.secondMessage }}
    </div>

    <ng-container
      *ngIf="
        messagePopupData.code?.isMultiple ||
        messagePopupData.code?.isForBulkGateway ||
        messagePopupData.code?.isForBulkBagConfirmation ||
        messagePopupData.code?.isForUserCreate ||
        messagePopupData.code?.isForCreateLinkedAccount
      ">
      <div>
        <div
          class="ibc-body-text-medium confirmation-text"
          *ngIf="
            messagePopupData.code?.message !== '' &&
            !messagePopupData.code?.isForCreateLinkedAccount
          ">
          {{ getConfirmText() }}
        </div>
        <mat-form-field
          appearance="outline"
          class="mat-md w-full input-wrapper-none">
          <mat-label *ngIf="messagePopupData.code?.isForCreateLinkedAccount"
            >Account</mat-label
          >
          <input
            matInput
            type="text"
            [(ngModel)]="confirmValue" (blur)="validateConfirmValue()"
            [placeholder]="renderPlaceholder()" />
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        !messagePopupData.code?.isMultiple &&
        messagePopupData.code?.isOptionalTextbox
      ">
      <mat-form-field
        appearance="outline"
        class="mat-md w-full hawb-description-textarea">
        <mat-label>Notes*</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="2"
          data-shortcut-number="8"
          maxlength="500"
          [(ngModel)]="confirmValue"
          placeholder="Reason for blocking"
          (input)="commentBoxChange($event)">
        </textarea>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.isForDownload">
      <div class="ibc-body-text-large-bold confirmation-message">
        {{ popupMessages.downloadFileText }}
      </div>
      <mat-radio-group [(ngModel)]="fileType" class="download-file">
        <mat-radio-button value="csv">
          <span class="ibc-body-text-medium"> CSV </span>
        </mat-radio-button>
        <mat-radio-button value="excel">
          <span class="ibc-body-text-medium"> Excel </span>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.renderTable">
      <div class="shipment-gateway-table">
        <app-summary-table
          [summaryGridColumns]="shipmentGatewayPieceGridColumns"
          [tableData]="shipmentGatewayPieceTableData"
          [isFrom]="page"
          (proceedMasterShipmentEvent)="
            proccedAndCloseMasterShipmentSelection($event)
          "></app-summary-table>
      </div>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.renderMasterShipmentTable">
      <div class="shipment-gateway-master-shipments-table">
        <app-summary-table
          [summaryGridColumns]="masterShipmentTableColumnConfig"
          [tableData]="masterShipmentTableData"
          [isFrom]="tableisFrom"
          (proceedMasterShipmentEvent)="
            proccedAndCloseMasterShipmentSelection($event)
          ">
        </app-summary-table>
      </div>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.renderBagShipmentTable">
      <div class="shipment-gateway-table">
        <app-summary-table
          [summaryGridColumns]="shipmentBagGridColumns"
          [tableData]="shipmentBagPieceTableData"
          [isFrom]="page"
          (proceedMasterShipmentEvent)="
            proccedAndCloseMasterShipmentSelection($event)
          "></app-summary-table>
      </div>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.isForWarning">
      <div class="isWarning-Popup">
        <app-svg-icon
          [iconName]="'warning'"
          class="cursor"
          [isStaticSvg]="true"
          [isFrom]="'Popup'"
          (staticIconClicked)="closePopup()"></app-svg-icon>
        <div class="warning-message ibc-body-text-medium {{messagePopupData.code?.isForHandlingExisitingAccount ? 'warning-message-whitespace' : 'warning-message'}}">
          {{ messagePopupData.code?.message }}
        </div>
        <div
          class="form-container"
          *ngIf="
            messagePopupData.code?.isForWarning &&
            messagePopupData.code?.isForFirstLinkedAccount
          ">
          <mat-radio-group
            [(ngModel)]="selectedOption"
            aria-label="Select an option"
            class="radio-column-layout">
            <mat-radio-button
              *ngFor="let option of messagePopupData.code?.radioOptions"
              [value]="option">
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="popup-footer warning"
    *ngIf="
      messagePopupData.code?.isFooterRequired &&
      messagePopupData.code?.isForWarning &&
      !messagePopupData.code?.footerButtonList
    ">
    <button
      class="ibc-medium-button ibc-primary-default-button cursor"
      (click)="confirm()">
      <span class="ibc-body-text-regular">
        {{
          messagePopupData.code?.isAccountDependency
            ? popupButtons.Close
            : popupButtons.Ok
        }}
      </span>
    </button>
  </div>

  <div
    class="popup-footer warning"
    *ngIf="
      messagePopupData.code?.isFooterRequired &&
      messagePopupData.code?.isForWarning &&
      messagePopupData.code?.footerButtonList
    ">
    <button
      *ngFor="let button of this.messagePopupData.code?.footerButtonList"
      class="{{ button.btnClass }}"
      [disabled]="button.name === 'Proceed' && !selectedOption"
      (click)="handleFooterButtonActions(button.name)">
      <span class="ibc-body-text-regular">
        {{ button.name }}
      </span>
    </button>
  </div>

  <div
    class="popup-footer"
    *ngIf="
      messagePopupData.code?.isFooterRequired &&
      !messagePopupData.code?.isForWarning
    ">
    <button
      class="cursor ibc-medium-button {{
        messagePopupData.code?.isBulk
          ? 'ibc-caution-secondary-button'
          : !messagePopupData.code?.isMultiple &&
              messagePopupData.code?.isOptionalTextbox
            ? 'ibc-caution-secondary-button'
            : 'ibc-secondary-default-button'
      }}"
      (click)="closePopup()">
      <span class="ibc-body-text-regular">{{ getCancelButtonName() }}</span>
    </button>
    <button
      class="cursor ibc-secondary-accent-button-md"
      *ngIf="messagePopupData.code?.isForGatewayLocationMismatch"
      (click)="editShipment()">
      <span class="ibc-body-text-regular">{{ popupButtons.editShipment }}</span>
    </button>
    <ng-container *ngIf="messagePopupData.code?.isForDownload">
      <button
        class="ibc-medium-button ibc-primary-default-button"
        [ngClass]="!fileType ? '' : 'cursor'"
        (click)="confirm()"
        [disabled]="!fileType">
        <span class="ibc-body-text-regular">
          {{ popupButtons.Download }}
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="!messagePopupData.code?.isForDownload">
      <button
        class="ibc-medium-button {{
          messagePopupData.code?.isBulk
            ? 'ibc-caution-default-button'
            : !messagePopupData.code?.isMultiple &&
                messagePopupData.code?.isOptionalTextbox
              ? 'ibc-caution-default-button ibc-body-text-regular'
              : 'ibc-primary-default-button'
        }}"
        [ngClass]="getClassValueForEnableDisable() ? '' : 'cursor'"
        *ngIf="!isDeleteAction"
        (click)="confirm()"
        [disabled]="isButtonDisabled()">
        <span class="ibc-body-text-regular">
          {{ getPopupButtonLabel() }}
        </span>
      </button>
      <button
        class="ibc-medium-button {{
          messagePopupData.code?.isBulk
            ? 'ibc-caution-default-button'
            : 'ibc-primary-default-button'
        }}"
        [ngClass]="
          messagePopupData.code?.isMultiple &&
          confirmValue !== popupButtons.Delete
            ? ''
            : 'cursor'
        "
        *ngIf="isDeleteAction"
        (click)="confirm()"
        [disabled]="
          messagePopupData.code?.isMultiple &&
          normalizeText(confirmValue) !== normalizeText(requiredBulkDeleteText)
        ">
        <span class="ibc-body-text-regular">{{ popupButtons.Delete }}</span>
      </button>
    </ng-container>
  </div>
</div>
