import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import {
  customerServiceFormConfig,
  customerServiceTableColumnConfig,
  defaultCustomerServiceData,
  governmentInformationFields,
} from '../../accounts.config';
import {
  FormField,
  IDropdownResponse,
  ILookupDropdownResponse,
  SvgIcon,
  SvgIconProp,
} from 'src/app/shared/models/dynamic.model';
import {
  ActionIcons,
  actionText,
  BulkDeleteItemNames,
  conditionCheckConstants,
  DataTypes,
  formFieldTypes,
  MasterLookup,
  ModalPopupMessages,
  ModeIcons,
  pageType,
  popupWidth,
  selectedIdForSummaryTable,
  selectionTableNames,
  SingleDeleteHeaderText,
  StatusCodes,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import {
  DefaultInfoHeaderIcons,
  DefaultInfoTableHeaderIcons,
} from 'src/app/shared/utility/svg-constants';
import { ModalService } from 'src/app/shared/services/modal.service';
import { MessagePopupComponent } from 'src/app/shared/components/message-popup/message-popup.component';
import { SnakbarService } from 'src/app/shared/services/snakbar.service';
import { CommonService } from 'src/app/shared/services/common.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { SaveGovernmentDetails } from '../../store/accounts.action';
import {
  ICustomerService,
  IGovernmentInfoDetailsData,
} from 'src/app/features/model/sm-accounts';
import { AccountsService } from 'src/app/features/service/accounts.service';
import { AddInlineEditTableComponent } from 'src/app/shared/components/tables/add-inline-edit-table/add-inline-edit-table.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { ValidatorPattern } from 'src/app/shared/utility/validators.pattern';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-government-information',
  templateUrl: './government-information.component.html',
  styleUrl: './government-information.component.scss',
})
export class GovernmentInformationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('emailInput2') emailInput2!: ElementRef;
  @ViewChild(AddInlineEditTableComponent)
  private readonly addInlineEditTableComp!: AddInlineEditTableComponent;

  public accountGovtInfoForm!: FormGroup;
  public formFields!: FormField[];
  public errorMessages: any;
  public formFieldType = formFieldTypes;
  public actionIcons = ActionIcons;
  public customerServiceTableColumnConfig = customerServiceTableColumnConfig;
  public customerServiceFormConfig = customerServiceFormConfig;
  public tableName = selectionTableNames;
  public headerText = SingleDeleteHeaderText;
  public customerServiceTableHeaderIcons!: SvgIconProp[];
  public customerServiceSelectedRow!: any;
  public setGovernmentInfoWidth: any = {};
  public setGovernmentInfoErrorTooltip: any = {};
  public setGovernmentInfoTooltipEvent: any = {};
  public filteredClearanceTypeOptions!: Observable<IDropdownResponse[]>;
  public governmentInfoTabData$!: Observable<IGovernmentInfoDetailsData>;
  public governmentInfoTabFormData: any;
  public pageMode!: string;
  public customerServiceGridFormData: any;
  public customerServiceGridForm!: FormGroup;
  public emailCtrl = new FormControl(
    '',
    Validators.pattern(ValidatorPattern.Email_Validator)
  );
  public emailCtrl2 = new FormControl(
    '',
    Validators.pattern(ValidatorPattern.Email_Validator)
  );
  public emailsForInvoiceNotifications: any = [];
  public emailsForGatewayNotifications: any = [];
  public userInvoiceEmailData!: IDropdownResponse[];
  public filterUserInvoiceEmailData!: Observable<any[]>;
  public userGatewayEmailData!: IDropdownResponse[];
  public filterUserGatewayEmailData!: Observable<any[]>;
  public salesPersonData!: IDropdownResponse[];
  public filterSalesPersonOption!: Observable<any[]>;
  public ModeIcons = ModeIcons;
  public isEditClicked = false;
  public isAddClicked = false;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private deletedCustomerServicesList!: any;

  // variables for chip autocomplete
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = false;
  public emailErrorMessage = validationErrorMessages.governmentEmailError
  separatorKeysCodes = [ENTER, COMMA];

  constructor(
    private readonly formUtilsService: ValidatorService,
    private readonly modalService: ModalService,
    private readonly snakbarService: SnakbarService,
    public readonly commonService: CommonService,
    private readonly store: Store,
    private readonly accountsService: AccountsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly sharedService: SharedService,
  ) {
    this.setReqInit();
    this.governmentInfoTabData$ = this.store.select(
      state => state.accountsGovernmentInfo
    );
  }

  ngOnInit() {
    this.checkMode();
    this.dataFetch();
    this.getDeletedCustomerServices();
  }

  ngAfterViewInit(): void {
    this.customerServiceGridForm =
      this.addInlineEditTableComp.inlineCrudTableForm;
    if (this.pageMode !== ModeIcons.view) {
      this.resetFirstInputRow();
    }
    this.bindCustomerGridData();
    this.patchDataAfterBind();
    this.checkGovtInfoValidData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private setReqInit() {
    this.createForm();
    this.formFields = governmentInformationFields.fields.filter(
      controls => controls.type !== conditionCheckConstants.extra
    );
    this.getIconsForCustomerServiceTableInfoMode(ModeIcons.add);
    this.loadLookUpData();
  }

  public onCustomerServiceTableSelectionChange(selectedRows: any[]) {
    this.customerServiceSelectedRow = selectedRows;
    this.customerServiceTableDataSelected(selectedRows.length > 0);
  }

  public onCustomerServiceIconClicked(iconClicked: SvgIcon) {
    if (iconClicked.name === ActionIcons.add) {
      this.handleCustomerGridAdd();
    } else if (iconClicked.name === ActionIcons.save) {
      this.handleCustomerGridSave();
    } else if (iconClicked.name === ActionIcons.close) {
      this.handleCustomerGridCancel();
    } else if (iconClicked.name === ActionIcons.delete) {
      if((this.getCustomerGridFormRows().length === this.customerServiceSelectedRow.length) && this.pageMode == ModeIcons.view) {
        this.modalService.openPopup(
          {
              message: validationErrorMessages.customerServiceDelete,
              isFooterRequired: false,
            },
            ModalPopupMessages.Warning,
            MessagePopupComponent,
            popupWidth.deletePopup
          );
      } else {
        this.handleCustomerGridDelete();
      }
    } else if (iconClicked.name === ActionIcons.edit) {
      this.handleCustomerGridEdit();
    }
  }

  public checkControlForError(controlName: string) {
    return this.commonService.checkControlError(
      this.accountGovtInfoForm,
      controlName
    );
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setGovernmentInfoWidth,
      this.setGovernmentInfoTooltipEvent,
      this.setGovernmentInfoErrorTooltip
    );
  }

  public fetchErrorMessages(controlName: string): string {
    if (
      this.accountGovtInfoForm.get(controlName)?.errors?.['autocompleteError']
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.accountGovtInfoForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public dispatchBusinessFormChanges() {
    this.dataForDispatch(
      this.addInlineEditTableComp.inlineCrudTableDataSource.data
    );
    this.commonService.checkPristineAccordionData(false);
    this.validateGovernmentInfoTab();
  }

  private dataForDispatch(customerServiceData: ICustomerService[]) {
    const govtInfoData = {
      ...this.accountGovtInfoForm.value,
      customerService: customerServiceData,
    };
    console.log(govtInfoData,"govtInfoData", this.emailsForInvoiceNotifications, "this.emailsForInvoiceNotifications")
    this.store.dispatch(new SaveGovernmentDetails(govtInfoData));
  }

  private handleCustomerGridAdd() {
    this.isAddClicked = true;
    this.getIconsForAddIconClicked();
    this.clearAndPatchFormArray();
  }

  private getIconsForAddIconClicked() {
    const custServiceTableHeaderIcons = JSON.parse(
      JSON.stringify(DefaultInfoHeaderIcons)
    );
    const actionIconNames = [
      ActionIcons.add,
      ActionIcons.edit,
      ActionIcons.delete,
      ActionIcons.save,
      ActionIcons.close,
    ];
    this.customerServiceTableHeaderIcons = custServiceTableHeaderIcons
      .filter((val: SvgIconProp) => actionIconNames.includes(val.name))
      .map((val: SvgIconProp) => {
        val.isDisabled =
          val.name !== ActionIcons.save && val.name !== ActionIcons.close;
        return val;
      });
  }

  private handleCustomerGridSave() {
    if (this.pageMode !== ModeIcons.view) {
      this.isEditClicked ? this.saveEditedData() : this.saveAddedData();
    } else if (this.pageMode === ModeIcons.view) {
      const accountId = localStorage.getItem('accountId') ?? 0;
      let customerServiceData;
      if (!this.isAddClicked && this.isEditClicked) {
        customerServiceData = this.getCustomerGridFormRows().value;
      } else if (this.isAddClicked && !this.isEditClicked) {
        customerServiceData = [this.getCustomerGridFormRows().at(0).value];
        this.saveAddedData();
      }
      const payload = {
        accountId: +accountId,
        customerService: customerServiceData,
      };
      this.saveOrUpdateCustomerServiceInViewMode(payload);
    }
  }

  private saveEditedData() {
    if (this.customerServiceGridForm.valid) {
      this.resetFirstInputRow();
      this.getCustomerGridFormRows().at(0).enable();
      this.updateRowsAfterEdit(false);
      this.addInlineEditTableComp.getTableData();
      this.dispatchBusinessFormChanges();
    }
  }

  private saveAddedData() {
    if (this.customerServiceGridForm.valid) {
      this.addInlineEditTableComp.addNewRow();
      const newRow = this.getCustomerGridFormRows().at(0).value;
      newRow.isRowDisable = true;
      newRow.tempId = this.commonService.generateUniqueTempId();
      this.getCustomerGridFormRows()
        .at(this.getCustomerGridFormRows().length - 1)
        .patchValue(newRow);
      this.addInlineEditTableComp.loadAutocompleteForNewRow(
        this.getCustomerGridFormRows().length - 1
      );
      this.getCustomerGridFormRows()
        .at(this.getCustomerGridFormRows().length - 1)
        .disable();
      this.resetFirstInputRow();
      this.dispatchBusinessFormChanges();
    } else {
      this.getCustomerGridFormRows().at(0).markAllAsTouched();
    }
  }

  private saveOrUpdateCustomerServiceInViewMode(payload: any) {
    this.accountsService
      .addorUpdateCustomerService(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.status === StatusCodes.Success) {
            this.isAddClicked = false;
            this.isEditClicked = false;
            this.snakbarService.openCustomisedSnackBar(
              ModalPopupMessages.customerServiceAddUpdate(
                payload.customerService[0].id === 0
                  ? actionText.added
                  : actionText.update
              ),
              ModalPopupMessages.success
            );
            this.accountsService.setAccountFetchApi(true);
            this.clearAndPatchFormArray();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  private handleCustomerGridCancel() {
    if (this.pageMode === ModeIcons.view) {
      this.isAddClicked = false;
      this.clearAndPatchFormArray();
      this.getIconsForCustomerServiceTableInfoMode(ModeIcons.view);
    } else {
      this.getCustomerGridFormRows().at(0).reset();
      this.getCustomerGridFormRows().at(0).enable();
      if (this.isEditClicked) {
        this.updateRowsAfterEdit(true);
      }
    }
    this.snakbarService.openCustomisedSnackBar(
      ModalPopupMessages.actionCancel,
      ModalPopupMessages.success
    );
  }

  private updateRowsAfterEdit(isForCancel: boolean) {
    this.getCustomerGridFormRows().controls.forEach((control, index) => {
      if (!control.value['isRowDisable'] && index !== 0) {
        if (isForCancel) {
          const modifiedRow =
            this.governmentInfoTabFormData.customerService.find(
              (data: ICustomerService) => data.tempId === control.value.tempId
            );
          this.getCustomerGridFormRows().at(index).patchValue(modifiedRow);
        }
        this.getCustomerGridFormRows()
          .at(index)
          .get('isRowDisable')
          ?.setValue(true);
        this.getCustomerGridFormRows().at(index).disable();
      }
    });
    this.isEditClicked = false;
  }

  private handleCustomerGridDelete() {
    let messageType;
    let message;
    if (this.customerServiceSelectedRow.length > 1) {
      message = ModalPopupMessages.bulkDeleteMessage(
        this.customerServiceSelectedRow.length,
        BulkDeleteItemNames.CustomerServices
      );
      messageType = ModalPopupMessages.BulkDeleteHeader;
    } else {
      message =
        ModalPopupMessages.singleDeleteMessage(
          SingleDeleteHeaderText.CustomerService
        ) + `${this.customerServiceSelectedRow[0]?.serviceTypeId}?`;
      messageType = ModalPopupMessages.singleDeleteHeader(
        SingleDeleteHeaderText.CustomerService
      );
    }
    const dialogRef = this.modalService.openPopup(
      {
        message: message,
        isMultiple: true,
        isFooterRequired: true,
        isBulk: this.customerServiceSelectedRow.length > 1,
      },
      messageType,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.customerServiceDelete();
        }
      });
  }

  private customerServiceDelete() {
    this.customerServiceSelectedRow.forEach((customerService: any) => {
      const param =
        customerService.id === 0 ? selectedIdForSummaryTable.tempId : 'id';
      const rows = this.getCustomerGridFormRows();
      const indexToRemove: number[] = [];
      rows.controls.forEach((control, index) => {
        if (control.value[param] === customerService[param]) {
          indexToRemove.push(index);
        }
      });
      indexToRemove.forEach(index => {
        if (param === selectedIdForSummaryTable.Id) {
          rows.at(index).get('action')?.setValue(ActionIcons.delete);
          if (this.pageMode !== ModeIcons.view) {
            this.accountsService.setDeletedCustomerServicesData(
              this.deletedCustomerServicesList.concat(rows.at(index).value)
            );
          }
        }
        rows.removeAt(index);
      });
      this.addInlineEditTableComp.getTableData();
      this.dispatchBusinessFormChanges();
    });
    if (this.pageMode !== ModeIcons.view) {
      this.resetFirstInputRow();
      this.getIconsForCustomerServiceTableInfoMode(ModeIcons.add);
      this.addInlineEditTableComp.inlineTableSelection.clear();
    } else {
      this.deleteCustomerServiceInViewMode();
    }
  }

  private deleteCustomerServiceInViewMode() {
    const deletedIds = this.customerServiceSelectedRow.map(
      (selectedData: any) => selectedData.id
    );
    this.accountsService
      .deleteCustomerService({
        ids: deletedIds,
        action: ActionIcons.delete,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.status === StatusCodes.Success) {
            this.snakbarService.openCustomisedSnackBar(
              ModalPopupMessages.customerServiceAddUpdate(actionText.deleted),
              ModalPopupMessages.success
            );
            this.addInlineEditTableComp.inlineTableSelection.clear();
            this.accountsService.setAccountFetchApi(true);
          } else {
            this.errorHandlerService.handleApiError(data);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  private handleCustomerGridEdit() {
    this.isEditClicked = true;
    if (this.pageMode !== ModeIcons.view) {
      this.getCustomerGridFormRows().at(0).reset();
      this.getCustomerGridFormRows().at(0).disable();
    }
    this.customerServiceSelectedRow.forEach((customerService: any) => {
      const param =
        customerService.id === 0 ? selectedIdForSummaryTable.tempId : 'id';
      this.getCustomerGridFormRows().controls.forEach((control, index) => {
        if (control.value[param] === customerService[param]) {
          this.getCustomerGridFormRows()
            .at(index)
            .get('isRowDisable')
            ?.setValue(false);
          this.getCustomerGridFormRows().at(index).enable();
        }
      });
    });
    this.addInlineEditTableComp.inlineTableSelection.clear();
    this.getIconsForCustomerServiceTableInfoMode(ModeIcons.add);
  }

  private customerServiceTableDataSelected(data: boolean) {
    const modifiedIcons = this.customerServiceTableHeaderIcons.map(
      (val: SvgIconProp) => {
        if (val.name === ActionIcons.delete || val.name === ActionIcons.edit) {
          val.isDisabled = !data;
        } else if (
          (val.name === ActionIcons.save && this.pageMode !== ModeIcons.view) ||
          val.name === ActionIcons.add
        ) {
          val.isDisabled = data;
        }
        return val;
      }
    );
    this.customerServiceTableHeaderIcons = modifiedIcons;
  }

  private getIconsForCustomerServiceTableInfoMode(mode: string) {
    const custServiceTableHeaderIcons = JSON.parse(
      JSON.stringify(DefaultInfoTableHeaderIcons)
    );
    const filtered: SvgIconProp[] = [];
    if (mode === ModeIcons.add || mode === ModeIcons.edit) {
      custServiceTableHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.name !== ActionIcons.add && val.name !== ActionIcons.view) {
          filtered.push(val);
        }
      });
    } else if (mode === ModeIcons.view) {
      custServiceTableHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.name === ActionIcons.add) {
          val.isDisabled = false;
        } else if (
          val.name === ActionIcons.edit ||
          val.name === ActionIcons.delete ||
          val.name === ActionIcons.save ||
          val.name === ActionIcons.close
        ) {
          val.isDisabled = true;
        }
        if (val.name !== ActionIcons.view) {
          filtered.push(val);
        }
      });
    }
    this.customerServiceTableHeaderIcons = filtered;
  }

  private createForm() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      governmentInformationFields
    );
    this.accountGovtInfoForm = form;
    this.errorMessages = errorMessages;
  }

  public addForInvoiceEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.emailsForInvoiceNotifications.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.emailCtrl.setValue(null);
  }

  public addForGatewayEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.emailsForGatewayNotifications.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.emailCtrl2.setValue(null);
  }

  removeForInvoiceEmail(email: any): void {
    const index = this.emailsForInvoiceNotifications.indexOf(email);
    if (index >= 0) {
      this.emailsForInvoiceNotifications.splice(index, 1);
    }
    this.filterInvoiceEmailDropdown();
  }

  removeForGatewayEmail(email: any): void {
    const index = this.emailsForGatewayNotifications.indexOf(email);
    if (index >= 0) {
      this.emailsForGatewayNotifications.splice(index, 1);
    }
    this.filterGatewayEmailDropdown();
  }

  selectedForInvoiceEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailsForInvoiceNotifications.push(event.option.viewValue);
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
    this.filterInvoiceEmailDropdown();
    this.accountGovtInfoForm.patchValue({
      selectEmailsForInvoiceNotification: this.emailsForInvoiceNotifications
    });
    this.dispatchBusinessFormChanges();
  }

  selectedForGatewayEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailsForGatewayNotifications.push(event.option.viewValue);
    this.emailInput2.nativeElement.value = '';
    this.emailCtrl2.setValue(null);
    this.filterGatewayEmailDropdown();
    this.accountGovtInfoForm.patchValue({
      selectEmailsforGatewayNotification: this.emailsForGatewayNotifications
    });
    this.dispatchBusinessFormChanges();
  }

  private filterInvoiceEmailDropdown() {
    this.filterUserInvoiceEmailData = this.filterUserInvoiceEmailData.pipe(
      map((data: any[]) =>
        data.filter(
          record => !this.emailsForInvoiceNotifications.includes(record.name)
        )
      )
    );
  }

  private filterGatewayEmailDropdown() {
    this.filterUserGatewayEmailData = this.filterUserGatewayEmailData.pipe(
      map((data: any[]) =>
        data.filter(
          record => !this.emailsForGatewayNotifications.includes(record.name)
        )
      )
    );
  }

  private async loadLookUpData() {
    let [userEmailData, salesPersonData]: any =
      await Promise.all([
        this.getMasterLookups(MasterLookup.userEmail),
        this.getMasterLookups(MasterLookup.salesPerson),
      ]);

    const userEmail = this.sharedService.getItem('tokenInfo')?.username;
    if (userEmail) {
      const filterUsersData = userEmailData.filter((item: any) => item.name !== userEmail);
      this.userInvoiceEmailData = filterUsersData;
      this.userGatewayEmailData = filterUsersData;
    } else {
      this.userInvoiceEmailData = userEmailData;
      this.userGatewayEmailData = userEmailData;
    }

    this.filterUserInvoiceEmailData = this.autocompleteValueChanges(
      MasterLookup.selectEmailsForInvoiceNotification,
      this.userInvoiceEmailData
    );

    this.filterUserGatewayEmailData = this.autocompleteValueChanges(
      MasterLookup.selectEmailsforGatewayNotification,
      this.userGatewayEmailData
    );
    this.salesPersonData = salesPersonData;
    this.filterSalesPersonOption = this.autocompleteValueChanges(
      MasterLookup.salesPerson,
      this.salesPersonData
    );
    this.patchAllDropdownValuesData(this.governmentInfoTabFormData);
  }

  private getMasterLookups(requestType: string) {
    return new Promise((resolve, reject) => {
      try {
        this.commonService
          .getMasterLookUp(requestType)
          .subscribe((response: ILookupDropdownResponse) => {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve([]);
            }
          });
      } catch (error) {
        reject(new Error(''));
      }
    });
  }

  public onTypeSelected(selectedType: any, dropdownData: any, control: string) {
    const selectedDataType = dropdownData.find(
      (type: IDropdownResponse) => type.name === selectedType
    );
    if (selectedDataType) {
      this.accountGovtInfoForm.get(control)?.setValue(selectedDataType.id);
      this.dispatchBusinessFormChanges();
    }
  }

  public formAutocompleteValueChanges(
    controlName: string,
    apiControlName: string,
    dropdownResponse: any,
    formControlId?: string
  ) {
    this.commonService.getAutocompleteDropdownId(
      dropdownResponse,
      this.accountGovtInfoForm,
      controlName,
      apiControlName,
      false,
      formControlId
    );
  }

  private autocompleteValueChanges(
    controlName: string,
    dropdownData: IDropdownResponse[]
  ): Observable<IDropdownResponse[]> {
    const formControl = this.accountGovtInfoForm.get(
      controlName
    ) as FormControl;
    return formControl.valueChanges.pipe(
      startWith(''),
      map(val => {
        const filterValue =
          typeof val === DataTypes.String ? val.toLowerCase() : '';
        return dropdownData?.filter(
          item => item.name?.toLowerCase().includes(filterValue)
        );
      })
    );
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.pageMode = ModeIcons.view;
          this.accountGovtInfoForm.disable();
        } else {
          this.pageMode = data != '' ? data : ModeIcons.add;
          this.accountGovtInfoForm.enable();
        }
        this.getIconsForCustomerServiceTableInfoMode(this.pageMode);
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchGovernmentInfoTabData();
        }
      });
  }

  private fetchGovernmentInfoTabData() {
    this.governmentInfoTabData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(governmentInfoData => {
        this.governmentInfoTabFormData = governmentInfoData;
        this.accountGovtInfoForm.patchValue(this.governmentInfoTabFormData);
        this.emailsForInvoiceNotifications = this.governmentInfoTabFormData.selectEmailsForInvoiceNotification;
        this.emailsForGatewayNotifications = this.governmentInfoTabFormData.selectEmailsforGatewayNotification;
        if (this.customerServiceGridForm) {
          this.clearAndPatchFormArray();
        }
        this.areEmailsSame();
      });
  }

  private bindCustomerGridData() {
    const rowsLength = JSON.parse(
      JSON.stringify(this.getCustomerGridFormRows().length)
    );
    const customerServiceDataLength = JSON.parse(
      JSON.stringify(this.governmentInfoTabFormData.customerService.length)
    );
    const condition =
      this.isAddClicked &&
      !this.governmentInfoTabFormData.customerService.filter(
        (customer: ICustomerService) => !customer.isRowDisable
      ).length
        ? customerServiceDataLength
        : customerServiceDataLength - rowsLength;
    for (let i = 0; i < condition; i++) {
      this.addInlineEditTableComp.addNewRow();
      this.addInlineEditTableComp.loadAutocompleteForNewRow(i + 1);
    }
  }

  private patchDataAfterBind() {
    const customerServiceData =
      this.isAddClicked &&
      !this.governmentInfoTabFormData.customerService.filter(
        (customer: ICustomerService) => !customer.isRowDisable
      ).length
        ? [
            ...defaultCustomerServiceData,
            ...this.governmentInfoTabFormData.customerService,
          ]
        : this.governmentInfoTabFormData.customerService;
    this.getCustomerGridFormRows().patchValue(customerServiceData);
    this.getCustomerGridFormRows().disable();
    if (this.isAddClicked || this.pageMode !== ModeIcons.view) {
      this.getCustomerGridFormRows().at(0).enable();
    }
    this.addInlineEditTableComp.getTableData();
  }

  private resetFirstInputRow() {
    this.getCustomerGridFormRows().at(0).reset();
    this.getCustomerGridFormRows()
      .at(0)
      .patchValue(defaultCustomerServiceData[0]);
    this.getCustomerGridFormRows().at(0).markAsUntouched();
    this.getCustomerGridFormRows().at(0).updateValueAndValidity();
    this.addInlineEditTableComp.getTableData();
  }

  private clearAndPatchFormArray() {
    this.getCustomerGridFormRows().clear();
    this.addInlineEditTableComp.addNewRow();
    this.addInlineEditTableComp.loadAutocompleteForNewRow(0);
    this.bindCustomerGridData();
    this.patchDataAfterBind();
  }

  private getCustomerGridFormRows() {
    return this.customerServiceGridForm.get('rows') as FormArray;
  }

  private patchAllDropdownValuesData(formData: any) {
    const salesPersonObject = this.salesPersonData?.find(
      (item: IDropdownResponse) => item.id === formData.salesPersonId
    );
    this.accountGovtInfoForm
      .get('salesPerson')
      ?.setValue(salesPersonObject ? salesPersonObject.name : '');
  }

  private checkGovtInfoValidData() {
    this.accountsService.isCheckForGovernmentInfoData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          this.commonService.validateFormFields(this.accountGovtInfoForm);
          if (this.getCustomerGridFormRows().length <= 1) {
            this.getCustomerGridFormRows().markAllAsTouched();
            this.addInlineEditTableComp.inlineCrudTableForm.updateValueAndValidity();
            this.cdr.detectChanges();
          }
          this.validateGovernmentInfoTab();
        } else {
          this.accountGovtInfoForm.markAsPristine();
          this.cdr.detectChanges();
        }
      });
  }

  public onSameAsInvoiceEmailChange(event: any) {
    if (event.checked) {
      this.emailsForGatewayNotifications = [
        ...this.emailsForInvoiceNotifications,
      ];
    } else {
      this.emailsForGatewayNotifications = [];
    }
    this.accountGovtInfoForm.patchValue({
      selectEmailsforGatewayNotification: this.emailsForGatewayNotifications
    });
    this.dispatchBusinessFormChanges();
  }
  
  public areEmailsSame(): boolean {
    if (!this.emailsForGatewayNotifications?.length || !this.emailsForInvoiceNotifications?.length) {
      return false;
    }
    const setInvoice = new Set(this.emailsForInvoiceNotifications);
    const setGateway = new Set(this.emailsForGatewayNotifications);
    if (setInvoice.size !== setGateway.size) {
      return false;
    }
    for (const email of setInvoice) {
      if (!setGateway.has(email)) {
        return false;
      }
    }
    return true;
  }
  
  private validateGovernmentInfoTab() {
    if (this.accountGovtInfoForm.valid && this.getCustomerGridFormRows().length >= 2) {
      this.accountsService.checkGovernmentInfoDataValid(true);
    } else {
      this.accountsService.checkGovernmentInfoDataValid(false);
    }
    this.cdr.detectChanges();
  }

  private getDeletedCustomerServices() {
    this.accountsService.isDeletedCustomerServicesData
      .pipe(takeUntil(this.destroy$))
      .subscribe(deletedCustomerService => {
        this.deletedCustomerServicesList = deletedCustomerService;
      });
  }
}
