import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from 'src/app/shared/utility/url-constants';

@Injectable({
  providedIn: 'root',
})
export class IBCAuthService { 
    constructor(
        public baseService: BaseService,
        private readonly http: HttpClient
      ) {}

    public authorizeB2CToken(accessToken:string, ipAddress: string): Observable<any> {
    const url = URLConstants.authorize;
    return this.baseService.authorizeToken(url,accessToken, ipAddress);
    }

    public getIpAddressOfUSer(): Observable<any> {
      const url = URLConstants.ipAddress;
      return this.baseService.ipAddress(url);
    }
}