<div
  class="select-all-message ibc-body-text-large"
  *ngIf="isSelectAllMessageRequired">
  <div *ngIf="globalHeaderCheckboxState">
    {{ getAllItemsSelectedMessage() }}
    <span
      *ngIf="isInLastPage"
      class="text-hyperlink"
      (click)="selectAllCheckboxes()"
      (keydown)="$event.stopPropagation()"
      [attr.aria-hidden]="true">
      {{ getSelectAllItemsMessage() }}
    </span>
  </div>
</div>
<div class="ibc-summary-table {{ getBorderClass() ? 'add-border' : '' }}">
  <mat-table
    [dataSource]="dataSource"
    matSort
    matSortDisableClear
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="dragAndDropGridColumns($event)">
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-divider></mat-divider>
    <ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          [checked]="globalHeaderCheckboxState"
          [indeterminate]="indeterminate"
          (change)="onCheckAllChange($event)"
          [disabled]="dataSource.data.length === 0">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event.checked ? checked(row) : unchecked(row)"
          [checked]="isChecked(row)"
          (keydown)="$event.stopPropagation()">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="radioSelect">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="selectedRowId"
          (change)="onRadioChange(row)">
          <mat-radio-button [value]="row.id"></mat-radio-button>
        </mat-radio-group>
      </mat-cell>
    </ng-container>
    <ng-container
      *ngFor="let item of summaryCustomiseTableColumns; let i = index">
      <ng-container
        [matColumnDef]="item.key"
        *ngIf="
          commonTableService.displaySelectedColumnsToTable(
            item.key,
            summaryCustomiseTableColumns,
            displayedColumns
          )
        ">
        <mat-header-cell *matHeaderCellDef cdkDrag>
          <span class="column-header cursor">
            <span class="ibc-body-text-large">{{ item.label }}</span>
            <span
              mat-sort-header
              #sortIcon
              *ngIf="dataSource.data.length !== 0">
              <app-svg-icon
                class="sort-icon"
                [iconName]="'sort'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                [condition]="tableSortDirection"></app-svg-icon>
            </span>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            class="{{
              commonTableService.isHyperlink(item.label, isFrom)
                ? 'text-hyperlink'
                : (item.key === 'user' || item.key === 'facility') && isFrom === 'sm-roles-and-permissions'
                ? 'tooltip-column' : ''
            }}"
            (keydown)="$event.stopPropagation()"
            (click)="navigateToSpecificRecord(element)"
            [matTooltip]="(item.label === 'Shipment' || 
            (item.key === 'user' || item.key === 'facility') && isFrom === 'sm-roles-and-permissions') ? element[item.key] : ''"
            [matTooltipPosition]="'above'">
            {{
              item.key !== 'date' && item.key !== 'masterShipmentDateOfCreation'
                ? element[item.key]
                : (element[item.key] | date: dateFormat)
            }}
          </span>
          <span
            *ngIf="(item.key === 'consignee' && isFrom === 'hawb')"
            class="consignee-tooltip">
            <div
              matTooltip="{{ consigneeTooltip(element.tooltipInfo) }}"
              matTooltipClass="custom-tooltip-consignee"
              matTooltipPosition="right">
              <app-svg-icon
                [iconName]="'infoCircle'"
                [isStaticSvg]="true"
                [isFrom]="'page'"
                class="info-icon hawb-summary cursor"></app-svg-icon>
            </div>
          </span>
        </mat-cell>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="actions">
      <!-- Header Cell -->
      <mat-header-cell *matHeaderCellDef>
        <span class="ibc-body-text-large">Actions</span>
      </mat-header-cell>

      <!-- Data Cell -->
      <mat-cell *matCellDef="let row">
        <div class="gateway-actions-column">
          <!-- Dynamically Render Buttons -->
          <button
            *ngFor="let button of getActionButtons(row)"
            [ngClass]="{
              'ibc-secondary-default-button popup-row-button': true,
              cursor: true,
              'disabled-button':
                button === buttonNames.create &&
                row.isPlaceholderRow &&
                !canCreate(row)
            }"
            (click)="onActionButtonClicked(button, row)">
            {{ button }}
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Actions column -->

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>
        <div
          tabindex="-1"
          (click)="toggleOverlay(menuOverlay)"
          (keydown)="$event.stopPropagation()"
          matTooltip="Customize Columns"
          appIconClickShortcut>
          <mat-icon class="cursor view-more-icon">more_vert</mat-icon>
          <div #menuOverlay></div>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"></mat-cell>
    </ng-container>
  </mat-table>

  <ng-container *ngIf="dataSource.data.length === 0">
    <div class="ibc-grid-message-text initial-search-text">
      {{ dataSearched ? noMatchMessage : noSearchMessage }}
    </div>
  </ng-container>

  <mat-paginator
    *ngIf="dataSearched && dataSource.data.length > 0"
    class="ibc-table-pagination"
    [length]="totalCount"
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons
    (page)="onChangingPages($event)"></mat-paginator>
</div>

