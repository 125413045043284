import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersWithHyphen]',
})
export class NumbersWithHyphenDirective {
  private isFormatting = true; // Flag to manage formatting
  @Input() formatAsFein = false;

  constructor(
    private readonly el: ElementRef,
    private readonly control: NgControl // To access the form control
  ) {}

  @HostListener('input')
  onInput(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const cursorPosition = input.selectionStart ?? 0;

    // Remove non-numeric characters
    let rawValue = input.value.replace(/\D/g, '');

    // Enforce maximum of 9 digits
    if (rawValue.length > 9) {
      rawValue = rawValue.substring(0, 9);
    }

    // Restore raw value without formatting
    this.el.nativeElement.value = rawValue;

    // Update form control with raw value
    if (this.control?.control) {
      this.control.control.setValue(rawValue, { emitEvent: false });
    }

    // Restore cursor position
    setTimeout(() => {
      input.setSelectionRange(cursorPosition, cursorPosition);
    });
  }

  @HostListener('blur')
  onBlur(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const rawValue = input.value.replace(/\D/g, ''); // Remove all non-digit characters

    let formattedValue = '';
    if (this.formatAsFein) {
      formattedValue = this.formatFeinValue(rawValue); // Format as FEIN
    } else {
      formattedValue = this.formatStateTaxValue(rawValue); // Format as State Tax ID
    }

    input.value = formattedValue;

    // Update the form control with the formatted value
    if (this.control?.control) {
      this.control.control.setValue(formattedValue, { emitEvent: false });
    }

    this.isFormatting = true;
  }

  @HostListener('focus')
  onFocus(): void {
    const input = this.el.nativeElement as HTMLInputElement;

    if (this.isFormatting) {
      const rawValue = input.value.replace(/[-–]/g, ''); // Remove all hyphens
      input.value = rawValue;

      // Update form control with the raw value
      if (this.control?.control) {
        this.control.control.setValue(rawValue, { emitEvent: false });
      }

      // Ensure the cursor is placed at the end
      setTimeout(() => {
        input.setSelectionRange(rawValue.length, rawValue.length);
      });
    }
    this.isFormatting = false;
  }

  private formatFeinValue(rawValue: string): string {
    return rawValue.replace(/(\d{2})(\d{7})/, '$1-$2'); // Format XX-XXXXXXX
  }

  private formatStateTaxValue(rawValue: string): string {
    return rawValue.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2–$3'); // Format XXX-XX–XXXX
  }
}
