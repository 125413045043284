import {
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { Injectable, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayPositions } from '../utility/constants';
import { ComponentPortal } from '@angular/cdk/portal';
import { MenuOverlayComponent } from '../components/menu-overlay/menu-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class MenuOverlayService {
  @Input() toBeRenderedComponent!: any;
  private menuOverlayRef!: OverlayRef;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly overlay: Overlay
  ) {}

  public openSearchOverlay(
    searchOverlay: FlexibleConnectedPositionStrategyOrigin,
    isFromModule: string
  ) {
    this.getOverlayConfig(searchOverlay);
    const tooltipPortal = new ComponentPortal(MenuOverlayComponent);
    const tooltipRef = this.menuOverlayRef.attach(tooltipPortal);
    tooltipRef.instance.isFrom = isFromModule;
    this.menuOverlayRef.backdropClick().subscribe(() => {
      this.closeOverlay();
    });
  }
  public closeOverlay() {
    this.menuOverlayRef?.detach();
  }

  private getOverlayConfig(
    menuOverlay: FlexibleConnectedPositionStrategyOrigin
  ) {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(menuOverlay)
      .withPositions([
        {
          originX: OverlayPositions.end,
          originY: OverlayPositions.top,
          overlayX: OverlayPositions.end,
          overlayY: OverlayPositions.top,
          offsetX: -10,
          offsetY: 0,
        },
      ]);
    this.menuOverlayRef = this.overlay.create({
      positionStrategy,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }
}
