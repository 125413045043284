import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import { customerMaagementFields } from '../../accounts.config';
import {
  FormField,
  ILookupDropdownResponse,
} from 'src/app/shared/models/dynamic.model';
import {
  ActionIcons,
  conditionCheckConstants,
  MasterLookup,
  ModeIcons,
  pageType,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { CommonService } from 'src/app/shared/services/common.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SaveCustomerManagementDetails } from '../../store/accounts.action';
import { Store } from '@ngxs/store';
import { MatSelectChange } from '@angular/material/select';
import { AccountsService } from 'src/app/features/service/accounts.service';

@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrl: './customer-management.component.scss',
})
export class CustomerManagementComponent {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public customerManagementTabData$!: Observable<any>;
  public icons = ActionIcons;
  public customerManagementTabFormData: any;
  public pageMode!: string;
  public formFields!: FormField[];
  public customerManagementForm!: FormGroup;
  public errorMessages: any;
  public clearanceTypeData: any[] = [];
  public clearanceChannelData: any[] = [];
  public fullmanFileValidationData: any[] = [];
  public selectedClearanceType: string[] = [];
  public selectedClearanceChannel: any[] = [];
  public setCustomerManagementErrorTooltip: any = {};
  public setCustomerManagementTooltipEvent: any = {};
  public setCustomerManagementWidth: any = {};

  constructor(
    private readonly formUtilsService: ValidatorService,
    public readonly commonService: CommonService,
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
    private readonly accountsService: AccountsService
  ) {
    this.setInitReq();
  }

  ngOnInit() {
    this.dataFetch();
    this.checkMode();
  }

  ngAfterViewInit(): void {
    this.checkCustomerManagementValidData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private setInitReq() {
    this.createForm();
    this.formFields = customerMaagementFields.fields.filter(
      controls => controls.type !== conditionCheckConstants.extra
    );

    this.loadLookUpData();

    this.customerManagementTabData$ = this.store.select(
      state => state.accountsCustomerManagement
    );
  }

  private createForm() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      customerMaagementFields
    );
    this.customerManagementForm = form;
    this.errorMessages = errorMessages;
  }

  public dipatchCustomerManagementFormChanges() {
    const clearanceTypeData = this.clearanceTypeData.filter(item =>
      this.customerManagementForm.value.clearanceType.includes(item.id)
    );
    const clearanceChannelData = this.clearanceChannelData.filter(item =>
      this.customerManagementForm.value.clearanceChannel.includes(item.id)
    );
    this.store.dispatch(
      new SaveCustomerManagementDetails({
        ...this.customerManagementForm.value,
        clearanceTypeData,
        clearanceChannelData,
      })
    );
    this.commonService.checkPristineAccordionData(false);
    this.validateCustomereManagementTab();
  }

  private async loadLookUpData() {
    const [clearanceTypeData, clearanceChannel, fullmanFileValidation]: any =
      await Promise.all([
        this.getMasterLookups(MasterLookup.clearanceType),
        this.getMasterLookups(MasterLookup.clearanceChannel),
        this.getMasterLookups(MasterLookup.fullmanFileValidation),
      ]);

    this.clearanceTypeData = clearanceTypeData;
    this.clearanceChannelData = clearanceChannel;
    this.fullmanFileValidationData = fullmanFileValidation;
  }

  private getMasterLookups(requestType: string) {
    return new Promise((resolve, reject) => {
      try {
        this.commonService
          .getMasterLookUp(requestType)
          .subscribe((response: ILookupDropdownResponse) => {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve([]);
            }
          });
      } catch (error) {
        reject(new Error(''));
      }
    });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchCustomerManagementTabData();
        }
      });
  }

  private fetchCustomerManagementTabData() {
    this.customerManagementTabData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(customerManagementData => {
        this.customerManagementTabFormData = customerManagementData;
        this.customerManagementForm.patchValue(
          this.customerManagementTabFormData
        );
      });
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.pageMode = ModeIcons.view;
          this.customerManagementForm.disable();
        } else {
          this.pageMode = data != '' ? data : ModeIcons.add;
          this.customerManagementForm.enable();
        }
      });
  }

  public onSelectionClearanceTypeChange(event: MatSelectChange) {
    const value = event.value;
    this.selectedClearanceType = value.filter((data: any) => data !== 'All');
    this.updateFormControl();
  }

  private updateFormControl() {
    this.customerManagementForm
      .get('clearanceType')
      ?.setValue(this.selectedClearanceType);
    this.dipatchCustomerManagementFormChanges();
  }

  private deselectAllClearanceType() {
    this.selectedClearanceType = [];
    this.updateFormControl();
    this.dipatchCustomerManagementFormChanges();
  }

  public clearClearanceType() {
    this.deselectAllClearanceType();
  }

  public onSelectionclearanceChannelChange(event: MatSelectChange) {
    const value = event.value;
    this.selectedClearanceChannel = value;
    this.updateclearanceChannelFormControl();
  }

  private updateclearanceChannelFormControl() {
    this.customerManagementForm
      .get('clearanceChannel')
      ?.setValue(this.selectedClearanceChannel);
    this.dipatchCustomerManagementFormChanges();
  }

  private deselectAllclearanceChannel() {
    this.selectedClearanceChannel = [];
    this.updateclearanceChannelFormControl();
    this.dipatchCustomerManagementFormChanges();
  }

  public clearclearanceChannel() {
    this.deselectAllclearanceChannel();
  }

  public onfullmanFileValidationChange(event: any) {
    if (event.value) {
      this.customerManagementForm
        .get('fullmanFileValidation')
        ?.setValue(event.value);
      const selectedFullManFile = this.fullmanFileValidationData.filter(
        (data: any) => data.name === event.value
      );
      this.customerManagementForm
        .get('fullmanFileValidationId')
        ?.setValue(selectedFullManFile[0].id);
      this.dipatchCustomerManagementFormChanges();
    }
  }

  private validateCustomereManagementTab() {
    if (this.customerManagementForm.valid) {
      this.accountsService.checkCustomerManagementDataValid(true);
    } else {
      this.accountsService.checkCustomerManagementDataValid(false);
    }
    this.cdRef.detectChanges();
  }

  private checkCustomerManagementValidData() {
    this.accountsService.isCheckForCustomerManagementData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          this.commonService.validateFormFields(this.customerManagementForm);
          this.validateCustomereManagementTab();
        } else {
          this.customerManagementForm.markAsPristine();
          this.cdRef.detectChanges();
        }
      });
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setCustomerManagementWidth,
      this.setCustomerManagementTooltipEvent,
      this.setCustomerManagementErrorTooltip
    );
  }

  public getErrorMessage(controlName: string) {
    if (this.customerManagementForm.get(controlName)?.errors?.['autocompleteError']) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.customerManagementForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public checkControlHasError(controlName: string) {
    return this.commonService.checkControlError(this.customerManagementForm, controlName);
  }
}
