<div class="menu-overlay-container">
  <mat-checkbox
    *ngFor="let item of summaryCustomiseTableColumns; let i = index"
    class="example-margin"
    [disabled]="item.key === defaultSelectedColumn"
    [checked]="item.isSelected"
    (click)="handleCustomizeCheckboxSelection(i, item.isSelected)"
    (keydown)="$event.stopPropagation()">
    <span class="ibc-body-text-regular">{{ item.label }}</span></mat-checkbox
  >
</div>
