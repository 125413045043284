<div class="crud-table ibc-border-table table-input-field">
  <mat-table [dataSource]="inlineCrudTableDataSource">
    <mat-header-row
      *matHeaderRowDef="inlineTableDisplayedColumns"
      class="ibc-body-text-large-bold"></mat-header-row>

    <ng-container>
      <mat-row
        *matRowDef="let row; columns: inlineTableDisplayedColumns"></mat-row>
    </ng-container>

    <mat-divider></mat-divider>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="inlineTableSelection.hasValue() && isAllSelected()"
          [indeterminate]="inlineTableSelection.hasValue() && !isAllSelected()"
          [disabled]="checkForEnableOrDisable() || isEditIconClicked">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index">
        <mat-checkbox
          *ngIf="
          !tableService.inlineEditTableRowContainsInput(row) &&
            (i > 0 || (i === 0 && pageMode !== 'Add'))
          "
          (change)="
            $event.checked ? checked(row) : unchecked(row);
            emitCreateSelectionChange()
          "
          [checked]="isChecked(row)"
          [disabled]="isEditIconClicked"
          (keydown)="$event.stopPropagation()">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <form [formGroup]="inlineCrudTableForm">
      <ng-container
        formArrayName="rows"
        *ngFor="let item of customiseInlineCrudTableColumns; let i = index"
        [matColumnDef]="item.key">
        <ng-container
          *ngFor="let roleForm of getRows().controls; let rowIndex = index">
          <mat-header-cell *matHeaderCellDef>
            <span class="column-header cursor">
              <span class="ibc-body-text-large">{{ item.label }}</span>
            </span>
          </mat-header-cell>

          <mat-cell
            *matCellDef="let element; let rowIndex = index"
            [formGroupName]="rowIndex">
            <mat-form-field
              appearance="outline"
              class="mat-sm mat-form-field-disabled"
              *ngIf="getInlineTableAutocompleteFields(item.key)">
              <mat-icon matPrefix class="dropdown-icon">
                {{ actionIcons.keyboardDownArrow }}
              </mat-icon>
              <input
                matInput
                [formControlName]="item.key"
                [matAutocomplete]="autoCompleteDropdown"
                (input)="formAutocompleteValueChanges(item.key, rowIndex)" />
              <mat-autocomplete
                #autoCompleteDropdown="matAutocomplete"
                (keydown)="$event.stopPropagation()"
                (optionSelected)="
                  onOptionSelectedFormChanges(item.key, rowIndex)
                ">
                <mat-option
                  *ngFor="
                    let data of getAutocompleteOptionsList(item.key, rowIndex)
                      | async
                  "
                  [value]="getAutocompleteOptionsValue(item.key, data)">
                  {{ getAutocompleteOptionsValue(item.key, data) }}
                </mat-option>
              </mat-autocomplete>
              <app-svg-icon
                *ngIf="checkControlHasError(rowIndex, item.key)"
                matSuffix
                [iconName]="'infoCircle'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                class="cursor info-icon-error"
                (click)="fetchWidth($event, item.key)"
                (keydown)="$event.stopPropagation()"></app-svg-icon>
              <app-custom-error-tooltip
                *ngIf="
                  checkControlHasError(rowIndex, item.key) &&
                  setInlineCrudTableErrorTooltip[item.key]
                "
                [tooltipWidth]="setInlineCrudTableWidth[item.key]"
                [tooltipText]="getErrorMessage(rowIndex, item.key)"
                (tooltipCloseClicked)="
                  commonService.closeErrorTooltip(
                    item.key,
                    setInlineCrudTableErrorTooltip
                  )
                ">
              </app-custom-error-tooltip>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="mat-sm"
              *ngIf="getInlineTableInputFormControl(item.key)">
              <input
                *ngIf="getDiscountWithDecimal(item.key)"
                matInput
                [formControlName]="item.key"
                [appDigitWithTwoDecimalNumber]="2" />
              <app-svg-icon
                *ngIf="checkControlHasError(rowIndex, item.key)"
                matSuffix
                [iconName]="'infoCircle'"
                [isStaticSvg]="true"
                [isFrom]="'Page'"
                class="cursor info-icon-error"
                (click)="fetchWidth($event, item.key)"
                (keydown)="$event.stopPropagation()"></app-svg-icon>
              <app-custom-error-tooltip
                *ngIf="
                  checkControlHasError(rowIndex, item.key) &&
                  setInlineCrudTableErrorTooltip[item.key]
                "
                [tooltipWidth]="setInlineCrudTableWidth[item.key]"
                [tooltipText]="getErrorMessage(rowIndex, item.key)"
                (tooltipCloseClicked)="
                  commonService.closeErrorTooltip(
                    item.key,
                    setInlineCrudTableErrorTooltip
                  )
                ">
              </app-custom-error-tooltip>
            </mat-form-field>
          </mat-cell>
        </ng-container>
      </ng-container>
    </form>
  </mat-table>
</div>
