import { Injectable } from '@angular/core';
import {
  downloadInfoPageSectionHeaders,
  AttachmentDownloadRequestType,
  downloadInfoPageSectionKeys,
  AccordionTabs,
} from '../utility/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  public printTableData = new BehaviorSubject<any>({});
  public isSetDataForPreviewTable = new BehaviorSubject<any>({});

  public setPrintTableData(data: any) {
    this.printTableData.next(data);
  }

  public setDataForPreviewTable(data: any) {
    this.isSetDataForPreviewTable.next(data);
  }

  public getHeaderSectionKeys(sectionName: string) {
    const sectionKeyMap = {
      [downloadInfoPageSectionKeys.mawbInfo]:
        AttachmentDownloadRequestType.mawbInfo,
      [downloadInfoPageSectionKeys.mawbmiscellaneous]:
        downloadInfoPageSectionHeaders.mawbmiscellaneous,
      [downloadInfoPageSectionKeys.details]: AccordionTabs.Details,
      [downloadInfoPageSectionKeys.events]: AccordionTabs.Events,
      [downloadInfoPageSectionKeys.attachments]: AccordionTabs.Attachments,
      [downloadInfoPageSectionKeys.notes]: AccordionTabs.Notes,
      [downloadInfoPageSectionKeys.mawbManifests]:
        downloadInfoPageSectionHeaders.mawbManifests,
      [downloadInfoPageSectionKeys.mawbFlights]:
        downloadInfoPageSectionHeaders.mawbFlights,
      [downloadInfoPageSectionKeys.manifestInfo]:
        downloadInfoPageSectionHeaders.manifestInfo,
      [downloadInfoPageSectionKeys.manifestsBag]:
        downloadInfoPageSectionHeaders.manifestBag,
      [downloadInfoPageSectionKeys.widgets]:
        downloadInfoPageSectionHeaders.widgets,
      [downloadInfoPageSectionKeys.blockUnblock]:
        downloadInfoPageSectionHeaders.blockUnblock,
      [downloadInfoPageSectionKeys.hawbInfo]:
        downloadInfoPageSectionHeaders.hawbInfo,
      [downloadInfoPageSectionKeys.hawbCommodity]:
        downloadInfoPageSectionHeaders.hawbCommodity,
      [downloadInfoPageSectionKeys.hawbRoles]:
        downloadInfoPageSectionHeaders.hawbRoles,
      [downloadInfoPageSectionKeys.hawbMiscellaneous]:
        downloadInfoPageSectionHeaders.hawbMiscellaneous,
      [downloadInfoPageSectionKeys.hawbStatusCode]:
        downloadInfoPageSectionHeaders.hawbStatusCode,
      [downloadInfoPageSectionKeys.hawbConsignee]:
        downloadInfoPageSectionHeaders.hawbConsignee,
      [downloadInfoPageSectionKeys.hawbShipper]:
        downloadInfoPageSectionHeaders.hawbShipper,
      [downloadInfoPageSectionKeys.bagInfo]:
        downloadInfoPageSectionHeaders.bagInfo,
      [downloadInfoPageSectionKeys.bagMiscellaneous]:
        downloadInfoPageSectionHeaders.bagMiscellaneous,
      [downloadInfoPageSectionKeys.bagPieces]:
        downloadInfoPageSectionHeaders.bagPieces,
      [downloadInfoPageSectionKeys.bagCriteria]:
        downloadInfoPageSectionHeaders.bagCriteria,
      [downloadInfoPageSectionKeys.userInfo]:
        downloadInfoPageSectionHeaders.userInfo,
      [downloadInfoPageSectionKeys.userDepartments]:
        downloadInfoPageSectionHeaders.userDepartments,
      [downloadInfoPageSectionKeys.loginInfo]:
        downloadInfoPageSectionHeaders.loginInfo,
    };
    return sectionKeyMap[sectionName] || '';
  }

  // public getWidthForInfoPageHeaders() {

  // }
}
