import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { SpinnerService } from '../../shared/services/spinner.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  public browserRefresh = false;

  constructor(
    private readonly http: HttpClient,
    private readonly spinnerService: SpinnerService,
    private readonly sharedService: SharedService    
  ) {}

  initiateApiRequest = (
    method: 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PUT',
    url: string,
    body: any = null,
    contentType = 'application/json',
    responseType: 'arraybuffer' | 'blob' | 'text' | 'json' = 'json',
    queryParams: HttpParams = new HttpParams()
  ): Observable<any> => {
    this.spinnerService.showSpinner();
    return this.getHttpRequest(
      method,
      url,
      body,
      responseType,
      contentType,
      queryParams
    );
  };

  public getHttpRequest = (
    method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'HEAD' | 'JSONP' | 'OPTIONS',
    url: string,
    body: any,
    responseType: 'arraybuffer' | 'blob' | 'text' | 'json' = 'json',
    contentType = 'application/json',
    queryParams: any
  ): Observable<any> => {
    let headers = new HttpHeaders();
    //if (method !== 'GET') {
      headers = headers.set('authorization',this.sharedService.getItem('jwtToken'));
    //}
    return this.http
      .request(method, url, {
        body,
        headers,
        responseType,
        params: queryParams,
      })
      .pipe(
        map<any, any>((response: any) => {
          this.spinnerService.hideSpinner();
          return response;
        })
      );
  };

  public uploadFile = (url: string, file: string): Observable<any> => {
    this.spinnerService.showSpinner();
    const formData = new FormData();
    formData.append('fileName', file);
    const headers = new HttpHeaders({
      'x-ms-blob-type': 'BlockBlob',
    });
    return this.http.put(url, formData, {
      headers,
      responseType: 'text',
    });
  };

  public authorizeToken = (url: string, accessToken: string, ipAddress: string): Observable<any> => {
    this.spinnerService.showSpinner(); 
    const headers = new HttpHeaders({
      'authorization': accessToken,
    });
    return this.http.get(url + `/${ipAddress}`, {
      headers,
      responseType: 'json'
    });
  };
 
  public ipAddress = (url: string): Observable<any> => {
    this.spinnerService.showSpinner(); 
    return this.http.get(url, {
      responseType: 'json'
    });
  };
}
