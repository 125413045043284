<div
  class="ibc-application-header {{ isOverlayOpened ? 'overlay-opened' : '' }}">
  <div class="header-container" title="PACTrak">
    <img src="assets/Logo.svg" alt="" />
    <div class="user-info-section">
      <div class="user-basic-info">
        <span class="ibc-body-text-large-bold">{{
          userFirstName | titlecase
        }}</span>
        <span class="ibc-body-table-text-small">Miami</span>
      </div>
      <span
        [matMenuTriggerFor]="menu"
        class="user-info-avatar ibc-heading-h4"
        >{{ userIntial }}</span
      >
      <mat-menu #menu="matMenu" class="app-header-menu">
        <mat-card class="user-info-card" appearance="outlined">
          <mat-card-header>
            <div mat-card-avatar class="user-avatar">
              <span>{{ userIntial }}</span>
            </div>
            <mat-card-title class="ibc-body-text-large-bold">{{
              userFullName | titlecase
            }}</mat-card-title>
            <mat-card-subtitle class="ibc-body-table-text-small"
              >Miami</mat-card-subtitle
            >
          </mat-card-header>
          <mat-card-actions>
            <button mat-button (click)="redirectToUserProfile()">
              User Profile
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-menu>
      <app-svg-icon
        class="logout"
        [iconName]="'logout'"
        [isStaticSvg]="true"
        [isFrom]="'Page'"
        matTooltip="Logout"
        (click)="logout()"
        (keydown)="$event.stopPropagation()"
        aria-controls="logout"></app-svg-icon>
    </div>
  </div>
</div>
