import { Injectable } from '@angular/core';
import {
  IAccountsInformationData,
  ICustomerService,
} from '../model/sm-accounts';
import { BehaviorSubject, Observable } from 'rxjs';
import { URLConstants } from 'src/app/shared/utility/url-constants';
import { BaseService } from 'src/app/core/services/base.httpService';
import {
  AttachmentDownloadRequestType,
  BulkDeleteItemNames,
  ModalPopupMessages,
  popupWidth,
} from 'src/app/shared/utility/constants';
import { MessagePopupComponent } from 'src/app/shared/components/message-popup/message-popup.component';
import { ModalService } from 'src/app/shared/services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  public ischeckAccordionTabsInfoValid = new BehaviorSubject<boolean>(false);
  public isCheckForAddressInfoData = new BehaviorSubject<boolean>(false);
  public isCheckForGovernmentInfoData = new BehaviorSubject<boolean>(false);
  public isCheckForCustomerManagementData = new BehaviorSubject<boolean>(false);
  public requiredAccountInfoData = new BehaviorSubject<any>({});
  public isFromPageSummary = new BehaviorSubject<string>('');
  public isCheckForGovernmentInfoDataValid = new BehaviorSubject<boolean>(
    false
  );
  public isCheckForCustomerManagementDataValid = new BehaviorSubject<boolean>(
    false
  );
  public isCreatedLinkedAccountData = new BehaviorSubject<any>('');
  public isDeletedCustomerServicesData = new BehaviorSubject<
    ICustomerService[]
  >([]);
  public isAccountPrevSummary = new BehaviorSubject<any>('');
  public isOrganizationAPICall = new BehaviorSubject<boolean>(false);
  public isFetchAccountApi = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly baseService: BaseService,
    private readonly modalService: ModalService
  ) {}

  public checkAccordionTabsInfoValid(data: boolean) {
    this.ischeckAccordionTabsInfoValid.next(data);
  }

  public checkAddressInfoData(data: boolean) {
    this.isCheckForAddressInfoData.next(data);
  }

  public checkGovernmentInfoData(data: boolean) {
    this.isCheckForGovernmentInfoData.next(data);
  }

  public checkCustomerManagementData(data: boolean) {
    this.isCheckForCustomerManagementData.next(data);
  }

  public checkGovernmentInfoDataValid(data: boolean) {
    this.isCheckForGovernmentInfoDataValid.next(data);
  }

  public checkCustomerManagementDataValid(data: boolean) {
    this.isCheckForCustomerManagementDataValid.next(data)
  }

  public setDeletedCustomerServicesData(data: ICustomerService[]) {
    this.isDeletedCustomerServicesData.next(data);
  }

  public checkOrganizationCRUDSuccess(data: boolean) {
    this.isOrganizationAPICall.next(data);
  }

  public setRequiredDataForAccountInfoPage(data: any): void {
    this.requiredAccountInfoData.next(data);
  }

  public setPageFrom(data: string) {
    this.isFromPageSummary.next(data);
  }

  public setLinkedAccountData(data: any) {
    this.isCreatedLinkedAccountData.next(data);
  }

  public isAccountSummaryPrevSearch(data: string) {
    this.isAccountPrevSummary.next(data);
  }

  public setAccountFetchApi(data: boolean) {
    this.isFetchAccountApi.next(data);
  }

  public createAccount(data: IAccountsInformationData): Observable<any> {
    return this.baseService.initiateApiRequest(
      'PUT',
      URLConstants.createAccount,
      data,
      'json'
    );
  }

  public updateAccount(data: IAccountsInformationData): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.updateAccount,
      data,
      'json'
    );
  }

  public getSearchData(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.accountSearch,
      event,
      '',
      'json'
    );
  }

  public checkAccountExistence(accountId: number): Observable<any> {
    const url = URLConstants.checkAccountInfo + accountId;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public autogenerateAccountNumber(accountNumber: string): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.autogenerateAccountNumber,
      accountNumber,
      '',
      'json'
    );
  }

  public createFirstAccountNumber(reqBody: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.firstLinkedAccountNumber,
      reqBody,
      '',
      'json'
    );
  }

  public deleteAccount(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteAccount,
      data,
      'json'
    );
  }

  public addorUpdateCustomerService(data: any) {
    return this.baseService.initiateApiRequest(
      'PUT',
      URLConstants.addOrUpdateCustomerGrid,
      data,
      'json'
    );
  }

  public deleteCustomerService(data: any) {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteCustomerGrid,
      data,
      'json'
    );
  }

  // Organisation
  public createOrganization(payload: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.createOrganization,
      payload,
      '',
      'json'
    );
  }

  public updateOrganization(payload: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.updateOrganization,
      payload,
      '',
      'json'
    );
  }

  public fetchOrganization(organizationId: number): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.fetchOrganization + organizationId,
      '',
      'json'
    );
  }

  public deleteOrganization(payload: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteOrganization,
      payload,
      '',
      'json'
    );
  }

  public validateOrganization(organizationId: string): Observable<any> {
    return this.baseService.initiateApiRequest(
      'GET',
      URLConstants.validateOrganization + organizationId,
      '',
      'json'
    );
  }

  public openAccountDeletePopup(selection: any) {
    const message =
      selection.length > 1
        ? ModalPopupMessages.bulkDeleteMessage(
            selection.length,
            BulkDeleteItemNames.Accounts
          )
        : ModalPopupMessages.singleDeleteMessage(
            AttachmentDownloadRequestType.account
          ) + `${selection[0].accountNumber}?`;
    const dialogRef = this.modalService.openPopup(
      {
        message: message,
        isFooterRequired: true,
        isBulk: selection.length > 1,
        isMultiple: true,
        isForCreateLinkedAccount: false,
      },
      selection.length > 1
        ? ModalPopupMessages.BulkDeleteHeader
        : ModalPopupMessages.singleDeleteHeader(
            AttachmentDownloadRequestType.account
          ),
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    return dialogRef;
  }
}
