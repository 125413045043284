import {
  IDepartment,
  UsersSearchPayload,
} from 'src/app/features/model/sm-users';

export class FetchUsersList {
  static readonly type = '[Users] List';
  constructor(public payload: UsersSearchPayload) {}
}

export class ResetUsersList {
  static readonly type = '[Users] Reset Users List';
}

// Department Information Section
export class SaveDepartmentDetails {
  static readonly type = '[Users Department Details] Save';
  constructor(public payload: IDepartment[]) {}
}

export class FetchUsersInformationListSuccess {
  static readonly type = '[User] Fetch Users Information List Success';
  constructor(public users: any[]) {}
}

export class FetchSelectedUserById {
  static readonly type = '[Users] Fetch Selected User By Id';
  constructor(public userId: number) {}
}

export class ResetUsersInformationList {
  static readonly type = '[Users] Reset Users Information List';
}
