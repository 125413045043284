import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  BagMiscellaneousState,
  BagPieceState,
  BagsListState,
  BagInformationState,
  BagInfoState,
  PieceBagState,
} from '../features/components/bag/store/bag.state';
import {
  RolesState,
  DispCodesState,
  PODState,
  MiscellaneousState,
  HawbInformationState,
  CommodityState,
  MiscellaneousCommodityState,
  HawbsListState,
  ConsigneeShippingState,
} from '../features/components/hawb/store/hawb.state';
import { ResetAllState } from '../features/components/hawb/store/reset.states';
import { NotesSectionState } from './components/notes/store/notes.state';
import { AttachmentState } from './components/attachments/store/attachments.state';
import { EventsState } from './components/events/store/events.state';
import { BagResetAllState } from '../features/components/bag/store/reset.states';
import {
  ManifestBagListState,
  ManifestDetailsState,
  ManifestInformationState,
  ManifestsListState,
} from '../features/components/manifest/store/manifest.state';
import { ManifestResetAllState } from '../features/components/manifest/store/reset.states';
import {
  FlightMiscellaneousState,
  FlightState,
  ManifestsMawbState,
  MawbDetailsState,
  MawbInformationState,
  MawbManifestState,
  MawbMiscellaneousState,
  MawbsListState,
} from '../features/components/mawb/store/mawb.state';
import { MawbResetAllState } from '../features/components/mawb/store/mawb.reset';
import {
  AttachmentAdvanceSearchModalState,
  BagAdvanceModalState,
  CommodityAdvanceModalState,
  ConsigneeAdvanceModalState,
  CrudAdvanceModalState,
  DetailAdvanceModalState,
  ManifestAdvanceModalState,
  MawbAdvanceModalState,
  MiscAdvanceState,
  MiscellaneousAdvanceModalState,
  NoteAdvanceModalState,
  ResetHawbAdvanceSearchState,
  RoleAdvanceModalState,
  StatusAdvanceModalState,
  WidgetAdvanceState,
} from './components/advanced-search/store/advance-search.state';
import {
  AccountInformationState,
  AccountListState,
  AccountsAddressInfoState,
  AccountsBusinessInfoState,
  AccountsCustomerManagementState,
  AccountsDetailState,
  AccountsGovernmentInfoState,
} from '../features/components/accounts/store/accounts.state';
import { AccountsResetAllState } from '../features/components/accounts/store/reset.state';
import {
  UsersDepartmentsInfoState,
  UsersListState,
  UserInformationState,
} from '../features/components/user-management/store/user.state';
import {
  FieldsAndTabsState,
  RoleInformationState,
  RolesAndPermissionPageSelectionState,
  RolesAndPermissionsListState,
} from '../features/components/roles-and-permissions/store/roles-permissions.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      BagMiscellaneousState,
      BagPieceState,
      RolesState,
      DispCodesState,
      PODState,
      AttachmentState,
      MiscellaneousState,
      HawbInformationState,
      CommodityState,
      MiscellaneousCommodityState,
      ResetAllState,
      BagResetAllState,
      EventsState,
      HawbsListState,
      ManifestsListState,
      BagsListState,
      BagInformationState,
      BagInfoState,
      PieceBagState,
      NotesSectionState,
      ManifestDetailsState,
      ManifestBagListState,
      ManifestResetAllState,
      ManifestInformationState,
      MawbDetailsState,
      MawbMiscellaneousState,
      MawbManifestState,
      FlightState,
      MawbsListState,
      ConsigneeShippingState,
      MawbResetAllState,
      FlightMiscellaneousState,
      MawbInformationState,
      ManifestsMawbState,
      WidgetAdvanceState,
      AttachmentAdvanceSearchModalState,
      DetailAdvanceModalState,
      CommodityAdvanceModalState,
      RoleAdvanceModalState,
      NoteAdvanceModalState,
      MiscellaneousAdvanceModalState,
      CrudAdvanceModalState,
      BagAdvanceModalState,
      ManifestAdvanceModalState,
      MawbAdvanceModalState,
      ConsigneeAdvanceModalState,
      StatusAdvanceModalState,
      MiscAdvanceState,
      ResetHawbAdvanceSearchState,
      AccountsDetailState, // S&M - Accounts
      AccountsAddressInfoState,
      AccountsBusinessInfoState,
      AccountsCustomerManagementState,
      AccountsGovernmentInfoState,
      AccountListState,
      AccountInformationState,
      AccountsResetAllState,
      UsersListState, // S&M - Users
      UsersDepartmentsInfoState,
      UserInformationState,
      FieldsAndTabsState, // Roles & Permissions
      RolesAndPermissionPageSelectionState,
      RoleInformationState,
      RolesAndPermissionsListState
    ]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [],
})
export class StoreModule {}
