import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {
  public getItem(key: string) {
    return JSON.parse(sessionStorage.getItem(key) ?? '{}');
  }

  public setItem(key: string, value: any) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  }

  public removeItem(key: string) {
    return sessionStorage.removeItem(key);
  }

  public clearEntireSession() {
    sessionStorage.clear();
  }

  public clearEntireLocalStorage() {
    localStorage.clear();
  }
}
